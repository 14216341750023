
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      tt:el.querySelector('.tt2'),
      tlabel:el.querySelector('.tlabel')
    }
    this.device = device
    this.active = 0

    this.create()
    
  }

  async create(){
    
    new window.SplitType(this.DOM.tt, {types: 'chars,words' })
    

    for(let [i,a] of this.DOM.tt.querySelectorAll('span').entries()){
      a.classList.add('st'+i)
    }
    
    this.anim = gsap.timeline({paused:true,onComplete:()=>{
      this.DOM.el.classList.add('act')
    }})
    .set(this.DOM.el,{opacity:1},0)
    if(this.device > 1){
      this.anim
      
    }
    else{
      this.anim
      .fromTo(this.DOM.tlabel,{opacity:0,y:-2.4+'rem'},{opacity:1,y:0,ease:Power2.easeInOut,duration:.8},.4)
      // .fromTo(this.DOM.tt,{opacity:0,y:2.4+'rem'},{opacity:1,y:0,ease:Power2.easeInOut,duration:1.2},.4)
    
      for(let [i,a] of this.DOM.tt.querySelectorAll('.char').entries()){
        let rand = Math.random() * (6 - 2) + 2

        this.anim
        .fromTo(a,{opacity:0,'-webkit-filter':'blur('+3+'px)', 'filter':'blur('+3+'px)'},{opacity:1,'-webkit-filter':'blur(0px)', 'filter':'blur(0px)',duration:1},(rand*.1))
              

      }
    
    }
    


  
  }
  
  async start(){
    this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
