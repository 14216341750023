
//import SplitType from 'split-type'


// import './index.scss'

export default class {
  constructor (obj,device,touch) {

    this.el = obj.el    
    this.el.parentNode.setAttribute('id','stck'+obj.pos)

    if(this.el.dataset.prior){
      this.prior = parseInt(this.el.dataset.prior)
    }

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    console.log(touch)
    this.DOM = {
      el:obj.el,
      parent:document.querySelector('#stck'+obj.pos),
      set:document.querySelector('#stck'+obj.pos+' .stck_move'),
      tts:obj.el.parentNode.querySelectorAll('.content_el'),
      mouseScroll: obj.el.parentNode.querySelector('.scroll_line_inner')
    }


    this.active = 0
    this.isupdate = 1
    
    
    this.h = window.innerHeight
    


    this.stick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0
    }

    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
    }



    this.onResize(window.scrollY)


    // this.navhead = document.querySelector('.nav_head')

    this.create()
  }
  
  create(){
    this.mouseanim = gsap.timeline({paused:true,repeat:-1,yoyo:false,repeatDelay:.6})
    .fromTo(this.DOM.mouseScroll,{translateY:-100+'%'},{translateY:100+'%',duration:.9},0)


    if(!this.stick || this.touch){
      return false
    }

    for(let [i,a] of this.DOM.tts.entries()){
      new window.SplitType(a, {types: 'chars,words' })
    }

      //TOP
     
      this.anim = gsap.timeline({paused:true})

      let randarr = []
      for(let [i,a] of this.DOM.tts.entries()){

        

          if(a.querySelector('span')){
            this.anim.fromTo(a.querySelector('span'),{color:'#fff'},{color:'#49D69D',duration:.6},.4+(Math.max(i,0)))
            
          }
          
          if(i==0){
            for(let [u,b] of a.querySelectorAll('.char').entries()){
              let rand = Math.random() * (6 - 2) + 2
              randarr.push(rand)
              this.anim
              .fromTo(b,{opacity:0,'-webkit-filter':'blur('+6+'px)', 'filter':'blur('+6+'px)'},{opacity:1,'-webkit-filter':'blur(0px)', 'filter':'blur(0px)',duration:.6-(rand*.1)},(rand*.1)+(Math.max(i,0)))
              
            }
          }
          else{
            for(let [u,b] of a.querySelectorAll('.char').entries()){
              let rand = Math.random() * (6 - 2) + 2
              randarr.push(rand)
              this.anim
              .fromTo(b,{opacity:0,'-webkit-filter':'blur('+6+'px)', 'filter':'blur('+6+'px)'},{opacity:1,'-webkit-filter':'blur(0px)', 'filter':'blur(0px)',duration:.6-(rand*.1)},.4+(rand*.1)+(Math.max(i,0)))
              
            }
          }
          
          

          if(i != 0){
            for(let [u,b] of this.DOM.tts[i-1].querySelectorAll('.char').entries()){
              
              let randout = Math.random() * (6 - 2) + 2
              if(randarr[u]){
                randout = randarr[u]
              }
              this.anim
              .fromTo(b,{opacity:1,'-webkit-filter':'blur(0px)', 'filter':'blur(0px)'},{opacity:0,'-webkit-filter':'blur('+6+'px)', 'filter':'blur('+6+'px)',duration:.6-(randout*.1),immediateRender:false},(randout*.1)+(Math.max(i,0)))
              
            }
        }


      }
      // .fromTo(this.DOM.bg,{scaleX:0,scaleY:.2},{scaleX:1,scaleY:1,duration:1,ease:Power2.easeInOut},0)
      // .to(this.DOM.bg,{opacity:1,duration:4},0)
      
    
  }
  check(entry,pos){
    let vis = false
    
    if(!this.stick ){
      return false
    }

      this.onResize(window.scrollY)
      pos = Math.floor(pos)
      

      if(entry.isIntersecting == true){
        vis = true
        document.documentElement.classList.add('scroll-stick')
        this.start()

      }
      else{
        document.documentElement.classList.remove('scroll-stick')
        this.stop()
      }

    return vis

  }
  
  start(){
    this.active = 1
    this.mouseanim.play()
  }
  
  stop(){
    this.active = 0
    this.mouseanim.pause()
  }

  initEvents(){
   

  }
  removeEvents(){

  }
  touchScroll(){
    
  }
  update(pos){
    if(!this.stick || this.touch){
      return false
    }

    if(this.device>1){
      // return false
      pos = window.scrollY
    }
      // this.stick.current = pos - this.stick.start
      // this.stick.current = clamp(0, this.stick.limit, this.stick.current)
      // this.stick.prog = this.stick.current / this.stick.limit

      this.animstick.current = pos - this.animstick.start
      this.animstick.current = gsap.utils.clamp(0, this.animstick.limit, this.animstick.current)
      this.animstick.prog = this.animstick.current  / this.animstick.limit
      this.anim.progress(this.animstick.prog)
     
      // this.DOM.set.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0,0,  -${this.animstick.current},  0, 1)`
      
  }

  onResize(pos){
    if(!this.stick || this.touch){
      return false
    }

    this.w = window.innerWidth
    this.h = window.innerHeight


      // this.DOM.fake.style.height = (this.h*.5 - this.DOM.tts[0].clientHeight*.5)+'px'

      this.DOM.el.style.height = this.animstick.limit+this.h+'px'

      if(!this.stick ){
        return false
      }


      this.animstick.start =  (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
      this.animstick.limit = this.DOM.tts.length*this.h

      // this.stick.start = (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
      // this.stick.limit = this.DOM.el.clientWidth + this.DOM.els.clientHeight - this.h
    
  }
  
  
}
