
// import './index.scss'

export default class {
  constructor (obj,device) {

    this.el = obj.el
   

    // this.device = device
    // this.touch = touch
    this.DOM = {
      el:this.el,
      tt:this.el.parentNode.querySelector('.tt1d'),
      btn:this.el.parentNode.querySelector('.Abtn'),
    }


    this.pos = 0
    this.create()
  }
  
  create(){
    new window.SplitType(this.DOM.tt, {types: 'chars,words' })
    this.anim = gsap.timeline({paused:true})
    .fromTo(this.DOM.btn,{opacity:0,y:2.4+'rem'},{opacity:1,y:0,ease:Power2.easeInOut,duration:.8},0)
    for(let [i,a] of this.DOM.tt.querySelectorAll('.char').entries()){
      let rand = Math.random() * (6 - 2) + 2

      this.anim
      .fromTo(a,{opacity:0,'-webkit-filter':'blur('+3+'px)', 'filter':'blur('+3+'px)'},{opacity:1,'-webkit-filter':'blur(0px)', 'filter':'blur(0px)',duration:1},(rand*.1))
            

    }
  }

  check(entry,pos){
    let vis = false
    if(this.active == 1){
      return false
    }
    if(entry.isIntersecting == true){
      vis = -1
      this.start()

    }
    else{

      this.stop()
    }

    return vis
  }
  start(){
    if(this.active == 1){
      return false
    }

    this.active=1
    this.anim.play()
  }
  stop(){

  }

  initEvents(){
   

  }
  removeEvents(){

  }
  update(pos){
   
  }

  onResize(pos){

  }
  
  
}
