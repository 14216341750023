//                                            `/+o/.
    //                        .+sso+/:oydyo/:-:+shdys/    `-:.     `-/+o+/`
    //                    `/sdh+/::/::ss:`ymdhyso//hmMNyhNNms+ososys+/-:/shms/`
    //                 .+hNNy++oo+/.`.--/osyhdmNNMMMMMMMMMNdsssssoso+hhhhsoo+ymdo.
    //               -smNy/+ymmmmmNNNNMNMMMMMNNNmmNMMMMMMMMMho:///:--shydNMMNdo-sNs`
    //             -hNd+-sNMNdmNMMMNNNMNNNMMMddNMMNNmNMMMMMMNmy+///::/:-:/++ymNNdmMN:
    //           `sNMs`+NMNNNMMMMNNNMMMMMMNmhyso///+ohMmoNMmoo+/::/-:oymNNmsosshdhmMM/
    //          +NMMy`hMMMhyNMNMMNNNMds:-.`-:syddmNMMmyo`+yMMho:..-+//++omMNNNNNNNmdNMs
    //        :mMMMh`yMNdodNNNMNMMMs.+sdmmmmmdhNMMMNhy/..`-syhNmdyssso+/.`:yNMMMMNMNMMMy
    //       :NMNh:-+MMh+mdNNNNNMd.+NNMMMMMMMMmho:-......:--::ohNMMMMMMNmNy/.oNMNmNMNMMMs
    //      :NMm+/dmmMNydyhNdhMMN.yMMNmhysso+:-``        ```.--:/+sdMMMMMNNNm:-mMNNNNMMMMy
    //     :NMy/hNMMMMmNddsh/NmMy-Mms:..`.--.`                ``..-.:yNMMMMNMNs:NMMMNNNNMMy
    //    :NNy/mMMMMMMmNMMshsNdMo/d-...``                       ```...-yMMMNNMd`NMMNMdmoNMM-
    //   /mMm+NMNNMMNMNNNNNNNNMMmom/                              ```..`+NMMMMh`NMMMMNNdhNMh
    //  +NMMmmMNyNMNMMMMMNmmmNMdNNyh+.                             ``````/NMMM::MMMMNMNNmNMN
    // +MNNMMMNymMNNMMMNNNNNMh+:+dNmddhyoo+`                        ````.`sMMN`sMNNMNNMNNNNN
    // dNNNMNNddMNNNNNNmymMN+---::/shdhyyy:                         `````..hMo.NMNMNMMMNmMMd
    // dNNNMMNmNNNmmNMNdNMM+.-..----.-:::.                          ````...:mh/NMMMNMMMNNMMh
    // sMNNMMNMNNmyNMNdmNMo--.....                                  ``...---:dNMNMMNMMNNNMMN.
    // :NNNMMMNNNsmMNmMNMy...`.-.`                                    `.-----:odNmmNMMMMMNMMo
    // .NMMMmMMMNmMNNNNMm:-.```..                                       ``-----:/o//dMMMNMMMm
    // .NMMMNMMNMMNMNNNNs--.``...                                          `....---..dMNMMMMM`
    // .NNMMNNNNNMMMNNNN:-...`...                                           ```.....`+MMMMMMM.
    // .MNNNNNNNMMMMMNNy.......-.`                                          ``..---.`.NMMMMMM`
    // `NMNMMNNNMMNMMMm-...`.-----.`                                        ``.-----.`yMMMMMd
    //  dMMMNNNNMMNNMMo`-....----..`          ``                      `.`` ```.------`:MMMMM-
    //  /MMNMNNNMMNMMN-`.`..-.--.` `--..-:-.-.``..``               ```.-......--.----..NMMMd
    //  `mMNMNNMMMNNMN.``...-.-../hddyysyhysyyso+--/::-..--...----:::+syyyyhhdddy+:-.-.hMMM:
    //   :NNNNNNMMMMMN.`....--.:dy/:-.-/+++ososss+/:+shyo/::/:+os+:+syosyoso+/://ss//.`+MMm
    //    +MdmNNMNMMMN+.--....:+-.-:+ooymdddmdhyo++ss+/yMo.`..oNsyhdhmdmmmmNmdo:-.--:+-:MM/
    //   `y/..-+dNNMMMo-shhyo++--+sso-`dsymoso.smyso+//.od+/:/ho+yyhd/ymsNhyy./yy/``.-hhmm`
    //   .s+md+- oMMMm``.-/sy//-.+/s.  odys+s-  /shyso+.sm+:::yd/hh+:`.hyyhy- `/y/.` `hs/s`
    //   -oyMNyhs:NMMo     `.-`         .---` ``.`/::+s/ms````-mo+:`````.--` ````     `sNm`
    //   `hsMh`.hymMM:       `-         `          .:+:hy`     od:-`                  .+sM-``
    //    o+o/``-/mMM-        .-                ``.```hy`       s.`.`                 -/+M+``
    //   .s `./NMMMM-         --            ````  `:ho`        .s`  ```             ./.+My`
    //    /: `+MMdMM/          -.  `       `   ..+++-           :d/.             ``:o-`oMy
    //     o. .sdNMMm`            `--:://+//.`-///:.           `.ohooo:-.`` `.-:+//:..`hMy
    //     `s```.yMMMs                  ```     .y+  `::.:----.-``o:-::/:::--:::-----..mMo
    //      :s` `oMNMN-                         :N+  -NNhy/:/sds./:..----------------`/MN.
    //       +o``-NMNMd`                      `-syyoo++/.++:so/+yN+..--....-..-....--`dM+
    //        +:.`oMNNN`                     .:-` `.::.` `--..---/+/---.```........-.:d:
    //         ./++Ny::`                   `--`          .--..-----::-..```......---.s.
    //           `:os.--`                  .`            `.. ``.------.`.```..-----.:o
    //             `h-..`                 ``        .:syy/-/ydho-.--...`````.------.+.
    //              +o`.`                        ./ymNNNNNNNmmNNNh:....``.```.-----:s
    //              `h-`.                    -/+oyo/:----:---.--:+sso:........--::-+:
    //               /d...                 `.++:  -:--/+:/oo+o++-.``--.....-----:-:y
    //               `Md:.`                ``     `-:/+ooooo+/-........-----------yo
    //                mNNs-`                     `..-/oo+://:/oo:......----------os
    //                h:+md:.                  ...``.`         `------.---------++
    //               `h..-+ddo.`                            ``.----------------s:
    //                 h` .--/ydy:`                   `...--------------------+y.
    //                 h`   ..--+yds+.`               `....----------------:+dN`
    //                `y      `.-.-:sdhs:.`    `...````..----------------:smsdm
    //                `h         .--..-+ymdy+/:----:----------------.-/shs+.`os
    //                `h           `..--..:sdmmhyo/::----------::/+syhy/....`+-
    //                -y              `..--..--/oosyyyhhhyyyssoooo/:.`...`.` /-
    //                `.                  `..--.......................````   +`
    //                                       `...------..-.........``
    //                                           ``..-.--........``
    //                                                ```..```





import '/scss🖌️🖌️🖌️/base🔧/normalize.scss'
import '/scss🖌️🖌️🖌️/base🔧/generics.scss'
import '/scss🖌️🖌️🖌️/base🔧/swiper.scss'
import '/scss🖌️🖌️🖌️/base🔧/♿.scss'


import '/scss🖌️🖌️🖌️/theme/rotate🔄.scss'
import '/scss🖌️🖌️🖌️/theme/scroll.scss'
import '/scss🖌️🖌️🖌️/theme/main🐙.scss'
// import '/scss🖌️🖌️🖌️/theme/grid.scss'

import '/scss🖌️🖌️🖌️/theme/fonts🔤.scss'
import '/scss🖌️🖌️🖌️/theme/wysi.scss'

// import '/scss🖌️🖌️🖌️/theme/uikit⚙️.scss'


import '/atoms🧿🧿🧿/btn.scss'
import '/atoms🧿🧿🧿/link.scss'
import '/atoms🧿🧿🧿/social.scss'
import '/atoms🧿🧿🧿/icon.scss'

import '/components🦾🦾🦾/Blocks/Ccontact/index.scss'
import '/components🦾🦾🦾/Footer🔥'

import browser from './browser🕸️.js'
import loadRestApi from './firstload📊.js'


import App from '/js🧠🧠🧠/main🐙'

import SplitType from 'split-type'

// import FontFaceObserver from 'fontfaceobserver'

import { gsap,Power2 } from "gsap"
import CustomEase from 'gsap/CustomEase'

if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual'
}



document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
document.documentElement.classList.add('lenis-stopped')

if(import.meta.env.DEV == true){

  document.documentElement.classList.add('dev')
  browser.revCheck()
  
  
}

const global = browser.browserCheck()
window.gsap = gsap
window.Power2 = Power2
window.gsap.registerPlugin(CustomEase)
window.gsap.ticker.remove(window.gsap.updateRoot)
window.SplitType = SplitType

CustomEase.create("jumpy","M0,0 C0,0 0.064,1.083 0.38,1.084 0.482,1.084 0.52,0.99 0.654,0.99 0.872,0.99 1,1 1,1 ")
    
CustomEase.create("blink","M0,0,C0,0,0.329,0.841,0.356,1,0.367,1.067,0.775,0.486,0.822,0.498,0.869,0.509,1,1,1,1")

// EL TIPO DE INCREMENTO PARA TAMAÑOS POR ENCIMA DE DISEÑO
global.design = {
  L:{
    w : 1440,
    h : 800,
    multi:.5,
    total:0,
    ratio:5.56,
    wide:((window.innerHeight*10)/window.innerWidth).toFixed(2),
    
  },
  P:{
    w:375,
    h:640,
    multi:.8,
    total:0
  }
}

// console.log(global.design.L.ratio)
// console.log(global.design.L.wide)

// EL MULTI, si queremos no crezcan los elemento sería 1
// si queremos que crezca de manera natural el rem, se pondría a 0

global.design.L.total = ( ( global.design.L.w / window.innerWidth ) * 10 )
// SE COGE LA DIFERENCIA ENTRE AMBAS Y SE HACE EL MULTI
global.design.L.total = 10 - ((10 - global.design.L.total) * global.design.L.multi)
// UN MATH.MIN PARA QUE NO SE PASE LA REDUCCIÓN Y HAGA EL EFECTO REM NATURAL
global.design.L.total = Math.min(10,global.design.L.total)


global.design.P.total = ( ( global.design.P.w / window.innerWidth ) * 10 )
// SE COGE LA DIFERENCIA ENTRE AMBAS Y SE HACE EL MULTI
global.design.P.total = 10 - ((10 - global.design.P.total) * global.design.P.multi)
// UN MATH.MIN PARA QUE NO SE PASE LA REDUCCIÓN Y HAGA EL EFECTO REM NATURAL
global.design.P.total = Math.min(10,global.design.P.total)


//MULTI PARA EL WIDE
// global.design.L.total *=  Math.min(1,(global.design.L.wide/global.design.L.ratio)*1.05)

document.documentElement.style.setProperty("--ck_multiL", global.design.L.total)
document.documentElement.style.setProperty("--ck_multiP", global.design.P.total)



//SIZES : Explicados en guides🔪🔪🔪
if(global.isTouch == 1){

  document.documentElement.style.setProperty("--ck_hscr", window.screen.height+'px')
  document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
  document.documentElement.style.setProperty("--ck_hmin", document.documentElement.clientHeight+'px')
  
}
else{

  document.documentElement.style.setProperty("--ck_hscr", window.innerHeight+'px')
  document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
  document.documentElement.style.setProperty("--ck_hmin", window.innerHeight+'px')

}



  let content = document.querySelector('#content')


	Promise.all([
    // fetch(document.body.dataset.js+'/wp-json/csskiller/v1/options?device='+global.device+'&webp='+global.webp).then(resp => resp.json()),
		loadRestApi.loadRestApi(
    {
      url:document.body.dataset.js+'/wp-json/csskiller/v1/options',
      device:global.device,
      webp:global.webp,
      id:content.dataset.id,
      template:content.dataset.template,
    })
    
    // new FontFaceObserver('TTFors').load(),
  
		
		
	]).then((loaded) => {
    new App([global,loaded[0]])
  })

