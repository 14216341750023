
export default class {
  constructor (obj,device,temp,webp) {
    

    this.el = obj.el    
    this.el.parentNode.setAttribute('id','stck'+obj.pos)

    this.pos = obj.pos
    this.device = device
    this.temp = temp
    this.webp = webp
    
    this.obj = { x : 0}

    this.DOM = {
      el:obj.el,
      parent:document.querySelector('#stck'+obj.pos),
      mouse: document.querySelector('#stck'+obj.pos).querySelector('.scroll'), 
      mouseScroll: document.querySelector('#stck'+obj.pos).querySelector('.scroll_line_inner') 
    }


    this.active = 0
    this.isupdate = 2
    
    
    this.h = window.innerHeight
    
    this.create()
  }
  
  create(){
   let canvas = document.createElement('canvas')

   this.DOM.parent.querySelector('.stck_sticky .canvashold').insertAdjacentElement('afterbegin',canvas)
   
   this.DOM.canvas = this.DOM.parent.querySelector('.stck_sticky canvas')
   this.DOM.canvashold = this.DOM.parent.querySelector('.stck_sticky .canvashold')


    this.preload = {
      pos:0,
      canvas:this.DOM.canvas,
      w:800,
      h:690,
      oX:.5,
      oY:.5,
      images:[],
      promises:[]
    }

    this.context = this.preload.canvas.getContext('2d')
    
    this.preload.canvas.width = this.DOM.parent.querySelector('.stck_sticky .canvashold').clientWidth
    this.preload.canvas.height = this.DOM.parent.querySelector('.stck_sticky .canvashold').clientHeight

    this.cover = this.coverCanvas(this.preload.w,this.preload.h,this.preload.canvas.width,this.preload.canvas.height)
    

    this.animstick = {
      active:0,
      current:0,
      target:0,
      limit:0,
      start:0,
      prog:0,
      currentt:0,
      total:0,
      progt:0
    }

    this.DOM.texts = this.DOM.el.parentNode.querySelectorAll('.texts_el')
    this.actualt = 0
    this.changeTit = (n=null) =>{
      this.DOM.texts[this.actualt].classList.remove('act')
      if(n != -1){
        this.actualt = n
        this.DOM.texts[this.actualt].classList.add('act')
      }

    }

    this.DOM.nummain = this.DOM.el.parentNode.querySelector('.counter_main')
      this.DOM.moving = this.DOM.el.parentNode.querySelector('.moving')

    this.DOM.numtext = this.DOM.el.parentNode.querySelector('.counter_text')
    

    //TOP
    this.createAnim()

    
  }
  createAnim(){
    this.mouseanim = gsap.timeline({paused:true,repeat:-1,yoyo:false,repeatDelay:.6})
    .fromTo(this.DOM.mouseScroll,{translateY:-100+'%'},{translateY:100+'%',duration:.9},0)

    this.anim = gsap.timeline({paused:true,onUpdate:()=>{
      
    }})
    
    .fromTo(this.DOM.nummain,{opacity:0},{opacity:1,duration:.45},14.5)
    .to(this.DOM.nummain,{color:'#49D69D',duration:.45},15.1)
    .to(this.obj,{x:450,duration:.45,onUpdate:()=>{
      let zero = '0'
      if(this.obj.x < 100){
        zero = '00'
      }
      else if(this.obj.x < 10){
        zero = '000'
      }
      this.DOM.moving.innerHTML = zero+Math.round(this.obj.x)
    }},15.1)
    .fromTo(this.DOM.numtext,{opacity:0},{opacity:1,duration:.45},15.55)
    .to(this.DOM.nummain.parentNode,{opacity:0,duration:1,immediateRender:false},19)
    .to(this.DOM.mouse,{opacity:0,duration:.45},19)
    
    .add(()=>this.changeTit(-1),0)
    .add(()=>this.changeTit(0),2.6)
    .add(()=>this.changeTit(1),6)
    .add(()=>this.changeTit(2),7.6)
    .add(()=>this.changeTit(3),9)
    .add(()=>this.changeTit(4),12)
    .add(()=>this.changeTit(4),14)
    .add(()=>this.changeTit(-1),15)
    .add(()=>this.changeTit(5),16)
    .add(()=>this.changeTit(5),18)
    .add(()=>this.changeTit(-1),20)


    // console.log(this.animstick.prog+' '+this.anim.progress())
  }
  async load(){
    let preloadtotal = 598
      let rnd = Math.floor(Math.random() * (4)) + 1

      let base = this.temp+'/csskiller/assets/desktoppng/'
      let type = '.png'
      let imagename = 'trumevideofinalsecuenciapng45fps690fondo'
      if(this.device > 1){
        base = this.temp+'/csskiller/assets/mobilepng/'
        imagename = 'trumevideofinalsecuenciapng45fps400fondo'
      }
      if(this.webp == 1){
        type += '.webp'
      }


      for(let i=0;i<preloadtotal;i++){
        let pos = ''
        if(i < 10) pos += '00'
        else if(i < 100) pos += '0'
        pos +=i
        let url = base+'/'+imagename+pos+type
        let ok = 0
        // 


        // console.log(ok)

        // if(ok != 0){
        // }
        //sin escalar
        ok = this.loadUrl(url)
        this.preload.promises.push(ok)
        //Escalado
        // await this.loadUrl(url)
        
        // console.log(this.preload.images.length)

      }
      await Promise.all(this.preload.promises)
      

      this.preload.images.sort(function (a, b) {
        if (a.src > b.src) {
          return 1
        }
        if (a.src < b.src) {
          return -1
          
        }
        return 0
      })

      this.draw()
      
  }


  loadUrl(url) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      
      // if(this.main.webp){
      
      img.onload = () =>{
        this.preload.images.push(img)
        resolve(img)
      }
      img.onerror = () =>{
        resolve(0)

      }

      img.src = url
      // }




    })

  }


  check(entry,pos){
    let vis = false

      

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
       
        this.stop()
      }

    return vis

  }
  
  start(){

    this.active = 1
    this.mouseanim.play()
  }
  
  stop(){
    
    this.active = 0
    this.mouseanim.pause()
   

  }

  initEvents(){
   

  }
  removeEvents(){

  }

  coverCanvas (contentWidth, contentHeight, containerWidth, containerHeight, offsetLeft, offsetTop) {
    var contentRatio = contentWidth / contentHeight;
    var containerRatio = containerWidth / containerHeight;
    var resultHeight;
    var resultWidth;
    offsetLeft = typeof offsetLeft === 'undefined' ? 0.5 : offsetLeft;
    offsetTop = typeof offsetTop === 'undefined' ? 0.5 : offsetTop;
    if (contentRatio > containerRatio) {
        resultHeight = containerHeight;
        resultWidth = containerHeight * contentRatio;
    }
    else {
        resultWidth = containerWidth;
        resultHeight = containerWidth / contentRatio;
    }
    return {
        width: resultWidth,
        height: resultHeight,
        offsetLeft:(containerWidth - resultWidth) * offsetLeft,
        offsetTop: (containerHeight - resultHeight) * offsetTop
    };
  }

  draw(){
    let chng = (this.animstick.prog*this.preload.images.length-1)/1
    
    
    this.preload.pos = window.gsap.utils.clamp(0,this.preload.images.length-1,Math.round(chng))
    
    this.context.clearRect(0, 0, this.cover.width,this.cover.height)
    // console.log(this.preload.images)
    // console.log(this.preload.pos)
    // console.log(this.preload.images[this.preload.pos])
    // console.log(this.cover.offsetLeft)
    // console.log(this.cover.offsetTop)
    // console.log(this.cover.width)
    // console.log(this.cover.height)
    this.context.drawImage(this.preload.images[this.preload.pos],this.cover.offsetLeft, this.cover.offsetTop, this.cover.width,this.cover.height)
  
  }
  lerp (t,s,r) {
    // return gsap.utils.interpolate(p1, p2, t)
    return t * (1 - r) + s * r
  }
  update(){
    this.animstick.current = window.scrollY - this.animstick.start - this.h
    this.animstick.current = window.gsap.utils.clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.target = this.lerp(this.animstick.current, this.animstick.target, 0.04)
    // this.animstick.prog = (this.animstick.target  / this.animstick.limit).toFixed(5)
    this.animstick.prog = (this.animstick.target  / this.animstick.limit)
    this.draw()
    
    this.anim.progress(this.animstick.prog)
    // this.animstick.currentt = pos - this.animstick.start
    // this.animstick.currentt = clamp(0, this.animstick.total, this.animstick.currentt)
    // this.animstick.progt = (this.animstick.currentt  / this.animstick.total).toFixed(5)
    

  }

  onResize(pos){

    
    this.w = window.innerWidth
    this.h = window.innerHeight

    this.animstick.start = 0
    this.animstick.limit = this.DOM.el.clientHeight - this.h
    this.animstick.total = this.DOM.parent.clientHeight - this.h

    this.preload.canvas.width = this.DOM.parent.querySelector('.stck_sticky .canvashold').clientWidth
    this.preload.canvas.height = this.DOM.parent.querySelector('.stck_sticky .canvashold').clientHeight

    this.cover = this.coverCanvas(this.preload.w,this.preload.h,this.preload.canvas.width,this.preload.canvas.height)
    if(this.preload){
      
      


     
      
      
        this.draw()
    }

    // if(this.anim){
    //   this.anim.kill()
    //   this.createAnim()
    //   this.update(window.scrollY)
    // }

  }
  
  
}
