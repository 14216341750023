
//VIEWS
//🟠🔴🔵🟢🟣🟡⚪⚫🟤
import Home from '/views👁️👁️👁️/⚪Home/home.js'
import Contact from '/views👁️👁️👁️/📩Contact/contact.js'
import Legal from '/views👁️👁️👁️/🔗Legal/legal.js'
import Error from '/views👁️👁️👁️/🚫404/404.js'


import '/views👁️👁️👁️/⚪Home/styles.js'
import '/views👁️👁️👁️/🔗Legal/styles.js'
import '/views👁️👁️👁️/📩Contact/styles.js'
import '/views👁️👁️👁️/🚫404/styles.js'


// import Uikit from '/views👁️👁️👁️/Uikit⚙️'
// import Project from '/views👁️👁️👁️/Project🟢'
// import Contact from '/views👁️👁️👁️/Contact🔵'


// import Legal from '/views👁️👁️👁️/🔗Legal'

  




export function createViews(){


  this.pages = new Map()
    this.pages.set('home', new Home(this.main))
    this.pages.set('page', new Legal(this.main))
    this.pages.set('contact', new Contact(this.main))
    this.pages.set('404', new Error(this.main))


}

