
export default class{
  constructor (obj,device,touch) {
    this.el = obj.el    
    

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
    }


    this.active = 0
    
    
    this.h = this.DOM.el.clientHeight
    
    this.create()
  }
  
  create(){
    
    //TOP
    // this.anim = gsap.timeline({paused:true})
    // for(let [i,a] of this.DOM.parent.querySelectorAll('.preload_info .line').entries()){
    //   // this.anim()
      
    //   this.anim.fromTo(a,{letterSpacing:0.06+'em',opacity:0},{letterSpacing:0+'em',opacity:1,duration:.8,ease:Power2.easeInOut},0)
    // }
    

    
  }
  

  check(entry,pos){
    let vis = false

    if(entry.isIntersecting == undefined){
      return false
    }


    let posactual = window.scrollY - this.startpos
   

    if(this.last > window.scrollY){

      
      if(posactual < 10){
        document.documentElement.classList.remove('dark-menu')

      }
      else if(posactual > this.h - 20){

        document.documentElement.classList.add('dark-menu')
      }
    
    }
    else{

      if(posactual > -3 && posactual < this.h){
        
        document.documentElement.classList.add('dark-menu')

      }
      else if(posactual > this.h){

        document.documentElement.classList.remove('dark-menu')
      }

    }


    this.last = window.scrollY

    // if(posactual >= 0 && (posactual < this.h)){
    //   document.documentElement.classList.add('menu-dark')

    // }
    // else{
    //   document.documentElement.classList.remove('menu-dark')
    // }
    return vis

  }
  
  start(){

    this.active = 1

  }
  
  stop(){
    
    this.active = 0

   

  }

  initEvents(){
   

  }
  removeEvents(){
    document.documentElement.classList.remove('dark-menu')

  }

  

  update(pos){

    

  }

  onResize(pos){

    this.last = window.scrollY
    this.h = this.DOM.el.clientHeight
    this.startpos = (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
   

  }
  


  
}
