
// import './index.scss'

export default class {
  constructor (el) {

    this.el = el    
   

    // this.device = device
    // this.touch = touch
    this.DOM = {
      el:el,
      elsclick:el.querySelectorAll('.content_tabs_sels .el'),
      elsdata:el.querySelectorAll('.content_tabs_data .el'),
      borders:el.querySelector('.content_tabs_data .borders'),
    }


    this.pos = 0
    this.create()
  }
  
  create(){

    this.DOM.elsclick[this.pos].classList.add('act')
    this.DOM.elsdata[this.pos].classList.add('act')
  
  }


  

  initEvents(){
   for(let [i,a] of this.DOM.elsclick.entries()){
    a.onclick = () =>{
      this.DOM.elsclick[this.pos].classList.remove('act')
      this.DOM.elsdata[this.pos].classList.remove('act')
      this.pos = i
      this.DOM.elsclick[this.pos].classList.add('act')
      this.DOM.elsdata[this.pos].classList.add('act')
    }

    a.onmouseenter = () =>{
      this.DOM.borders.classList.add('act')
    }
    a.onmouseleave = () =>{
      this.DOM.borders.classList.remove('act')
    }
   }

   

  }
  removeEvents(){

  }
  update(pos){
   
  }

  onResize(pos){

  }
  
  
}
