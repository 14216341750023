

export default class {
  constructor (obj,device) {

    this.el = obj.el    

    this.pos = obj.pos
    this.device = device
    
    this.DOM = {
      el:obj.el,
      bg:obj.el.parentNode.querySelector('.el_bg_in')
    }


    this.active = 0
    
    

    // this.navhead = document.querySelector('.nav_head')

    this.create()
  }
  
  create(){
    this.animstart = gsap.timeline({paused:true,repeat:-1,yoyo:true,repeatDelay:1})


    if(this.DOM.el.dataset.style==0){
      this.DOM.paths = this.DOM.el.parentNode.querySelectorAll('.el_anim_logo path')
      this.DOM.fig1 = this.DOM.el.parentNode.querySelectorAll('.el_anim_fig-1 svg')
      this.DOM.fig2 = this.DOM.el.parentNode.querySelectorAll('.el_anim_fig-2 svg')
      
      this.animstart.set(this.DOM.fig1[0],{opacity:1,immediateRender:false},0)
      this.animstart.set(this.DOM.fig2[0],{opacity:1,immediateRender:false},0)
      
      this.animstart.fromTo(this.DOM.fig1[1],{opacity:0},{opacity:1,ease:'blink',duration:.2},.2)
      this.animstart.fromTo(this.DOM.fig2[1],{opacity:0},{opacity:1,ease:'blink',duration:.2},.35)
     


      this.animstart.set(this.DOM.fig1[0],{opacity:0,immediateRender:false},.5)
      this.animstart.set(this.DOM.fig2[0],{opacity:0,immediateRender:false},.65)
      

      for(let [i,a] of this.DOM.paths.entries()){
        this.animstart.fromTo(a,{opacity:0},{opacity:1,duration:.1,delay:.5},.15*i)
        this.animstart.fromTo(a,{yPercent:50},{yPercent:0,ease:'jumpy',duration:.3,delay:.5,immediateRender:false},.15*i)
  
      }
    }
    else if(this.DOM.el.dataset.style==1){
      this.DOM.paths = this.DOM.el.parentNode.querySelectorAll('.el_anim_logo path')
      this.DOM.fig1 = this.DOM.el.parentNode.querySelectorAll('.el_anim_fig-1 svg')
      this.DOM.fig2 = this.DOM.el.parentNode.querySelectorAll('.el_anim_fig-3 svg')
      
      
      this.animstart.set(this.DOM.fig1[0],{opacity:1,immediateRender:false},0)
      this.animstart.set(this.DOM.fig2[0],{opacity:1,immediateRender:false},0)
      
      this.animstart.fromTo(this.DOM.fig1[1],{opacity:0},{opacity:1,ease:'blink',duration:.2},.2)
      this.animstart.fromTo(this.DOM.fig2[1],{opacity:0},{opacity:1,ease:'blink',duration:.2},.35)
     


      this.animstart.set(this.DOM.fig1[0],{opacity:0,immediateRender:false},.5)
      this.animstart.set(this.DOM.fig2[0],{opacity:0,immediateRender:false},.65)
      

      for(let [i,a] of this.DOM.paths.entries()){
        this.animstart.fromTo(a,{opacity:0},{opacity:1,duration:.1,delay:.5},.15*i)
        this.animstart.fromTo(a,{yPercent:50},{yPercent:0,ease:'jumpy',duration:.3,delay:.5,immediateRender:false},.15*i)
  
      }
    }
    else if(this.DOM.el.dataset.style==2){
      this.DOM.paths = this.DOM.el.parentNode.querySelectorAll('.el_anim_logo path')
      this.DOM.fig1 = this.DOM.el.parentNode.querySelectorAll('.el_anim_fig-1 svg')
      this.DOM.fig2 = this.DOM.el.parentNode.querySelectorAll('.el_anim_fig-3 svg')
      
      this.DOM.fig1b = this.DOM.el.parentNode.querySelectorAll('.el_anim_fig-1b svg')
      this.DOM.fig2b = this.DOM.el.parentNode.querySelectorAll('.el_anim_fig-4 svg')
      
      
      this.animstart.set(this.DOM.fig1[0],{opacity:1,immediateRender:false},0)
      this.animstart.set(this.DOM.fig2[0],{opacity:1,immediateRender:false},0)
      this.animstart.set(this.DOM.fig1b[0],{opacity:1,immediateRender:false},0)
      this.animstart.set(this.DOM.fig2b[0],{opacity:1,immediateRender:false},0)
      
      this.animstart.fromTo(this.DOM.fig1[1],{opacity:0},{opacity:1,ease:'blink',duration:.2},.1)
      this.animstart.fromTo(this.DOM.fig2[1],{opacity:0},{opacity:1,ease:'blink',duration:.2},.3)
      this.animstart.fromTo(this.DOM.fig1b[1],{opacity:0},{opacity:1,ease:'blink',duration:.2},.2)
      this.animstart.fromTo(this.DOM.fig2b[1],{opacity:0},{opacity:1,ease:'blink',duration:.2},.4)
     


      this.animstart.set(this.DOM.fig1[0],{opacity:0,immediateRender:false},.3)
      this.animstart.set(this.DOM.fig2[0],{opacity:0,immediateRender:false},.5)
      this.animstart.set(this.DOM.fig1b[0],{opacity:0,immediateRender:false},.4)
      this.animstart.set(this.DOM.fig2b[0],{opacity:0,immediateRender:false},.6)
      

      for(let [i,a] of this.DOM.paths.entries()){
        this.animstart.fromTo(a,{opacity:0},{opacity:1,duration:.1,delay:.6},.15*i)
        this.animstart.fromTo(a,{yPercent:50},{yPercent:0,ease:'jumpy',duration:.3,delay:.6,immediateRender:false},.15*i)
  
      }



    }



    // this.animbg = gsap.timeline({paused:true,yoyo:true,repeat:-1,repeatDelay:0})
    // .fromTo(this.DOM.bg,{opacity:.12},{opacity:.3,ease:'none',duration:2},0)
    // .fromTo(this.DOM.bg,{scaleX:1},{scaleX:1.2,duration:2,ease:'none'},0)
    // .to(this.DOM.bg,{opacity:1,duration:4},0)
      
    
  }
  check(entry,pos){
    let vis = false
    

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{

        this.stop()
      }
    return vis

  }
  
  start(){
    // //console.log('start intro')
    // this.animbg.play()
    this.animstart.play()
    this.active = 1
  }
  
  stop(){
    // //console.log('stop intro'+this.active)

    
    this.animstart.pause()
    this.animstart.progress(0)

    // this.animbg.pause()
    // this.animbg.progress(0)
    this.active = 0
    // //console.log('stop intro change'+this.active)

   

  }

  initEvents(){
   

  }
  removeEvents(){

  }
  touchScroll(){

  }
  update(pos){
   
  }

  onResize(pos){

  }
  
  
}
