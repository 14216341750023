
import { timeout } from '/js🧠🧠🧠/defaults/math🧮.js'
import './index.scss'



class Loader {
  constructor (main,temp,device) {
    
    this.main = main
    this.counter = 0
    this.index = 0
    this.temp = {init:temp,pop:temp}
    this.device = device
    
  }
  async create(){
    document.querySelector('body').insertAdjacentHTML('afterbegin',this.temp.init)
    
    this.DOM = {
      el: document.documentElement.querySelector('.loader'),
      
    }
    this.DOM.paths = this.DOM.el.querySelectorAll('svg path')
    // gsap.set(this.DOM.el.querySelector('#logop3 path'),{scaleY:0})
    this.createAnim()
  }
  
  createAnim(){
    this.anim = gsap.timeline({paused:true,repeat:-1,repeatDelay:.6})
    for(let [i,a] of this.DOM.paths.entries()){
      this.anim.fromTo(a,{opacity:0},{opacity:1,duration:.1,immediateRender:false},.15*i)
      this.anim.fromTo(a,{yPercent:50},{yPercent:0,ease:'jumpy',duration:.3,immediateRender:false},.15*i)

    }
   
  }
  

  async hide(){

  }
  async show(){
  }
  async start () {

    this.anim.play()
  }

  async showPop(){

    if(this.device > 1){
      document.querySelector('body').insertAdjacentHTML('afterbegin','<div class="loader loader-pop"></div>')
      this.DOM.el = document.querySelector('.loader')
      
    }
  }

  async hidePop(){
    if(this.device > 1){
      await gsap.to(this.DOM.el,{opacity:0,duration:.4,ease:Power2.easeInOut})
      this.DOM.el.remove()
    }
  }

  async hideIntro(template = ''){


    this.anim.progress(1)
    this.anim.pause(1)

      gsap.to(this.DOM.el,{opacity:0,duration:.3,ease:Power2.easeInOut,
        onComplete:()=>{
          this.DOM.el.remove()
        }
      })


    // gsap.fromTo(document.querySelector('.mousebg_el'),{scale:.6,yoyo:true,duration:.6,ease:Power2.easeInOut})
    
    // await gsap.to(this.DOM.el,{opacity:0,duration:.6,ease:Power2.easeInOut})
   
  }

}

export default Loader
