
import './index.scss'

class Nav {
  constructor (main) {
   
    this.main = main
    this.isOpen = 0

  }

  async create (temp) {

    document.querySelector('body').insertAdjacentHTML('afterbegin',temp)

   
    this.DOM = {
      el:document.querySelector('.nav'),
      burger:document.querySelector('.nav_burger'),
    }


    gsap.set(this.DOM.el,{opacity:0})
    


    this.initEvents()
  }
  async openMenu(){
    

    document.documentElement.classList.add('act-menu')
    document.dispatchEvent(this.main.events.openmenu)
  }
  async closeMenu(){
    document.documentElement.classList.remove('act-menu')
    document.dispatchEvent(this.main.events.closemenu)
    
  }

  async show(){

    gsap.fromTo(this.DOM.el,{opacity:0},{opacity:1,ease:Power2.easeInOut,duration:.6})
    
  }
  async hide(){
    
    gsap.to(this.DOM.el,{opacity:0,ease:Power2.easeInOut,duration:.6})
  }

  initEvents(){
    this.DOM.burger.onclick = () =>{
      if(this.isOpen == 1){
        this.closeMenu()
        this.isOpen = 0
      }
      else{
        this.openMenu()
        this.isOpen = 1
      }
    }


    
  }
  

  onResize(){
  }

}



export default Nav